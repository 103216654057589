<script setup lang="ts">
import { storeToRefs } from "pinia"
import IconButton from '~/components/UI/IconButton.vue'
import { useBannerStore } from '~/stores/useBannerStore'
import XMarkIcon from '@heroicons/vue/20/solid/XMarkIcon'
const { banner } = storeToRefs(useBannerStore())
const { closeBanner } = useBannerStore()
</script>

<template>
  <nav class="relative left-0 top-28 z-50 h-0 w-full bg-primary">
    <div class="mx-auto flex h-full w-auto items-center justify-between gap-4 px-4 xl:container md:gap-8 xl:px-0">
      <div class="w-full flex flex-col gap-4 text-[10px] sm:text-xs text-white font-light">
        <div class="flex gap-4 justify-center items-center" v-html="banner?.content" />
      </div>
    </div>
    <div class="absolute top-1 right-8">
      <IconButton variant="transparent" size="small" @click="closeBanner">
        <XMarkIcon class="text-slate-400 hover:text-slate-200" @click="closeBanner" />
      </IconButton>
    </div>
  </nav>
</template>